<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Not found" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Label"
                  v-model="label"
                  :error="!!errors.pagenotfound_label"
                  :error-messages="errors.pagenotfound_label"
                  @input="delete errors.pagenotfound_label"
                />
                <va-input
                  label="Description"
                  v-model="description"
                  :error="!!errors.pagenotfound_description"
                  :error-messages="errors.pagenotfound_description"
                  @input="delete errors.pagenotfound_description"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Button Label"
                  v-model="homeButtonLabel"
                  :error="!!errors.pagenotfound_button_home_label"
                  :error-messages="errors.pagenotfound_button_home_label"
                  @input="delete errors.pagenotfound_button_home_label"
                />
                <va-input
                  label="Button Link"
                  v-model="homeButtonLink"
                  :error="!!errors.pagenotfound_button_home_link"
                  :error-messages="errors.pagenotfound_button_home_link"
                  @input="delete errors.pagenotfound_button_home_link"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Button Label"
                  v-model="aboutButtonLabel"
                  :error="!!errors.pagenotfound_button_fund_label"
                  :error-messages="errors.pagenotfound_button_fund_label"
                  @input="delete errors.pagenotfound_button_fund_label"
                />
                <va-input
                  label="Button Link"
                  v-model="aboutButtonLink"
                  :error="!!errors.pagenotfound_button_fund_link"
                  :error-messages="errors.pagenotfound_button_fund_link"
                  @input="delete errors.pagenotfound_button_fund_link"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      label: '',
      description: '',
      homeButtonLabel: '',
      homeButtonLink: '',
      aboutButtonLabel: '',
      aboutButtonLink: '',

      errors: [],

      tabTitles: ['Main', 'Home', 'About'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        pagenotfound_label: this.label,
        pagenotfound_description: this.description,
        pagenotfound_button_home_label: this.homeButtonLabel,
        pagenotfound_button_home_link: this.homeButtonLink,
        pagenotfound_button_fund_label: this.aboutButtonLabel,
        pagenotfound_button_fund_link: this.aboutButtonLink,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.pagenotfound_label || !!this.errors.pagenotfound_description
      } else if (tab === 1) {
        return !!this.errors.pagenotfound_button_home_label || !!this.errors.pagenotfound_button_home_link
      } else if (tab === 2) {
        return !!this.errors.pagenotfound_button_fund_label || !!this.errors.pagenotfound_button_fund_link
      }
      return false
    },
    submit () {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-page/5?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-page/validate/5?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-page/5?lang=${this.locale}`)
        .then(response => {
          this.label = response.data.pagenotfound_label
          this.description = response.data.pagenotfound_description
          this.homeButtonLabel = response.data.pagenotfound_button_home_label
          this.homeButtonLink = response.data.pagenotfound_button_home_link
          this.aboutButtonLabel = response.data.pagenotfound_button_fund_label
          this.aboutButtonLink = response.data.pagenotfound_button_fund_link
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
